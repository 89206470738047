// import React, { useRef } from "react";
// import { Link } from "react-router-dom";
// import styles from "./footer.module.css";
// const Footer = () => {
//   return (
//     <footer className={`bg_dark fn_light w-100 text-center ${styles.footer}`}>
//       <p className="mt-4">
//         Copyright &copy;{" "}
//         <strong>
//           <a to="/" className="fn_light">
//             QUBX
//           </a>
//         </strong>{" "}
//         {new Date().getFullYear()} . All Rights Reserved. Powered By{" "}
//         <a href="https://teqneia.com/" target="_blank">
//           <img src="/images/footer/teqneia_logo.svg" alt="teqneia logo" />
//         </a>
//       </p>
//     </footer>
//   );
// };

// export default Footer;

import React, { useRef } from "react";
import { Link } from "react-router-dom";
import styles from "./footer.module.css";
const Footer = () => {
  return (
    <footer
      className={`bg_dark fn_light w-100 text-center position-absolute  ${styles.footer}`}
    >
      <p className="mt-4">
        Copyright &copy;{" "}
        <strong>
          <a href="https://qubxview.com/" target="_blank" className="fn_light">
            QUBX
          </a>
        </strong>{" "}
        {new Date().getFullYear()} . All Rights Reserved. Powered By{" "}
        <a href="https://teqneia.com/" target="_blank">
          <img src="/images/footer/teqneia_logo.svg" alt="teqneia logo" />
        </a>
      </p>
    </footer>
  );
};

export default Footer;
