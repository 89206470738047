import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// thunk for add note
export const addNote = createAsyncThunk(
  "note/create",
  async (noteData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await fetch("https://py.qubx3d.com/api/v1/note/create", {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(noteData),
      });
      return res;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const noteSlice = createSlice({
  name: "note",
  initialState: { notes: [] },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addNote.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addNote.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.notes.push(action.payload);
      })
      .addCase(addNote.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default noteSlice.reducer;
