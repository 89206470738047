import React, { useEffect, useState } from "react";
import "../../../styles/login.css";
import { Link, Navigate } from "react-router-dom";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Footer from "./../../footer/footer";
import { useSelector, useDispatch } from "react-redux";
import { login } from "../../../features/login/loginSlice";
import CustomToastify, { toastMes } from "../../../globalConfig/notification";
import { endpoints, httpNonToken } from "../../../globalConfig/http-non-token";
import { TextField, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import "./login.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Login = () => {
  const userDoctor = JSON.parse(localStorage.getItem("doctor"));

  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    mobile: "",
    password: "",
  });

  const { mobile, password } = formData;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const elementsToCheck = [1,2,4];
    if (!formData.mobile.trim() || !formData.password.trim()) {
      toastMes.error("Mobile and Password are required");
      return;
    }

    try {
      const loginRes = await httpNonToken.post(endpoints.login, {
        mobile: formData.mobile.trim(),
        password: formData.password,
      });
      const doesNotContainAny = elementsToCheck.every(element => !loginRes?.data?.user?.groups.includes(element));
      if (loginRes.status === 401) {
        toastMes.error("User Does Not Exist!");
      }
      if(doesNotContainAny){
        toastMes.error("Unauthorized! you don't have permission to login!"); 
    }else if(!doesNotContainAny){
      localStorage.setItem("doctor", JSON.stringify(loginRes.data));
      navigate("/requests");
      
  }
      // if (loginRes.data.access) {
      //   localStorage.setItem("doctor", JSON.stringify(loginRes.data));
      //   navigate("/requests");
      // }
    } catch ({ response }) {
      toastMes.error(response.data.detail);
    }
  };

  const forgetPassword = async () => {
    if (!formData.mobile) {
      toastMes.error("Mobile is required");
      return;
    }
    try {
      const forgetPasswordRes = await httpNonToken.put(
        `${endpoints.forgetPassword}/?mobile=${formData.mobile.trim()}`
      );
      if (forgetPasswordRes.status === 200) {
        toastMes.success("New Password will be sent to your phone");
        handleClose();
      } else {
        toastMes.success("Something went wrong ..!");
      }
    } catch ({ response }) {
      toastMes.error(response.data.message);
    }
  };

  return (
    <div className="container login-box">
      <div className="row justify-content-center bg-white m-5 content-login">
        <div className="col-md-6 p-0">
          <img
            className="w-100 h-100"
            src={"/images/form_rc.png"}
            alt="login image"
          />
        </div>
        <div className="col-md-6 my-5 ps-5 right-side">
          <h4 className="text-center mb-5 mt-5 f-main">
            Login to doctor panel
          </h4>
          <div className="login-doctor-form w-75 m-auto">
            <div className="mb-2 row">
              <TextField
                className="col-12 mb-4"
                id="outlined-basic-mobile"
                label="Mobile Number"
                name="mobile"
                variant="outlined"
                value={formData.mobile}
                onChange={onChange}
              />
              <TextField
                className="col-12"
                id="outlined-basic-password"
                type="password"
                label="Password"
                name="password"
                variant="outlined"
                value={formData.password}
                onChange={onChange}
              />
            </div>
            <div className="d-flex justify-content-end">
              <p
                onClick={handleClickOpen}
                className="text-decoration-underline cursor-pointer text-primary"
              >
                Forget Password ..!?
              </p>
            </div>

            <div className="d-flex justify-content-between">
              <Button
                variant="contained"
                onClick={handleSubmit}
                className="btn btn-primary m-auto mt-4"
              >
                login
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Forget Password</DialogTitle>
        <DialogContent>
          <div>
            <i className="fas fa-exclamation-triangle fa-3x text-danger text-center d-block my-2"></i>
          </div>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="text-center"
          >
            New Random Password will sent to your Phone Number in Message, and
            you can update it later.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={forgetPassword}>Proceed Anyway</Button>
        </DialogActions>
      </Dialog>

      <CustomToastify duration={6000} />
    </div>
  );
};

export default Login;
