import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../../../styles/viewRelatedCases.css";
import Header from "../../../header/header";
import moment from "moment";
import {
  endpoints,
  httpNonToken,
} from "../../../../globalConfig/http-non-token";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  TextField,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
//
import { enGB } from "date-fns/locale";
import { DateRangePicker, START_DATE, END_DATE } from "react-nice-dates";
import "react-nice-dates/build/style.css";
import "./requests.css";
//

const ViewRelatedCases = () => {
  const [tableData, setTableData] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [searchByName, setSearchByName] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [date, setDate] = useState("");
  const user = JSON.parse(localStorage.getItem("doctor"));
  const navigate = useNavigate();

  const handlePageChange = (pageNum) => {
    setPage(pageNum);
  };

  const fetchData = async () => {
    if (user) {
      try {
        const requestData = await httpNonToken.get(
          `${endpoints.request}?doctor__id=${
            user.user.id
          }&page=${page}&page_size=${pageSize}${
            selectedStatus ? `&statusView=${selectedStatus}` : ""
          }${
            startDate
              ? `&created_at__date__gte=${
                  new Date(startDate).toISOString().split("T")[0]
                }`
              : ""
          }${
            endDate
              ? `&created_at__date__lte=${
                  new Date(endDate).toISOString().split("T")[0]
                }`
              : ""
          }${searchItem ? `&patient__mobile__contains=${searchItem}` : ""}${
            searchByName ? `&patient__fullname__icontains=${searchByName}` : ""
          }`,
          {
            headers: {
              Authorization: `Bearer ${user.access}`,
            },
          }
        );

        setTableData(requestData.data);
      } catch ({ response }) {
        if (response.status === 401 && response.data.code) {
          localStorage.removeItem("doctor");
          navigate("/login");
          return;
        }
      }
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    page,
    pageSize,
    selectedStatus,
    searchByName,
    endDate,
    startDate,
    searchItem,
  ]);

  const getTodayRequests = () => {
    let today = moment(new Date()).format("YYYY-MM-DD");
    setDate(today);
  };

  const filterStatus = (id) => {
    setSelectedStatus(id);
    setDate("");
  };

  const clearAllFilter = () => {
    setSelectedStatus("");
    setSearchItem("");
    setSearchByName("");
    setStartDate(null);
    setEndDate(null);
  };

  const getStyle = (id) => {
    let style = {};
    style.borderRadius = "0px";
    style.textAlign = "center";
    style.padding = "5px";
    if (id === "In progress") {
      style.backgroundColor = "#77c3f6";
    } else if (id === "Delivered to Patient") {
      style.backgroundColor = "#A6efb5";
    } else if (id === "3D Printing") {
      style.backgroundColor = "#D5a4ff";
    } else if (id === "Cancelled") {
      style.backgroundColor = "#E6e6e6";
    } else {
      style.backgroundColor = "lightgrey";
    }
    return style;
  };

  return (
    <div className="viewRelatedPage">
      <Header />
      <div className="main-content">
        <div className="bg-white border m-4 table-content">
          <div className="mx-3" style={{ marginTop: "10px" }}>
            <Button variant="outlined" color="error" onClick={clearAllFilter}>
              Clear Filters
            </Button>
          </div>
          <div className="row mb-2 px-2  search-profile-doctor">
            <div className="searchDate col-sm-12 col-md-4">
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                onStartDateChange={setStartDate}
                onEndDateChange={setEndDate}
                minimumLength={1}
                format="dd MMM yyyy"
                locale={enGB}
              >
                {({ startDateInputProps, endDateInputProps, focus }) => (
                  <div className="date-range">
                    <input
                      className={
                        "input" + (focus === START_DATE ? " -focused" : "")
                      }
                      {...startDateInputProps}
                      placeholder="Start date"
                    />
                    <i className="fa-solid fa-angle-right text-secondary mx-2"></i>{" "}
                    <input
                      className={
                        "input" + (focus === END_DATE ? " -focused" : "")
                      }
                      {...endDateInputProps}
                      placeholder="End date"
                    />
                  </div>
                )}
              </DateRangePicker>
            </div>
            {window.innerWidth >= 768 ? (
              <div className="col-sm-12 col-md-3 search-profile-doctor-right-side mt-4">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Search By mobile"
                  variant="outlined"
                  size="small"
                  value={searchItem}
                  onChange={(e) => setSearchItem(e.target.value)}
                />
              </div>
            ) : null}
            <div className="col-sm-12 col-md-3 search-profile-doctor-right-side mt-4">
              <TextField
                fullWidth
                id="outlined-basic"
                label="Search By Patient Name"
                variant="outlined"
                size="small"
                value={searchByName}
                onChange={(e) => setSearchByName(e.target.value)}
              />
            </div>
            {/* <div className="search-profile-doctor-left-side ms-3">  */}

            <div className="searchStatus col-sm-12 col-md-2 ">
              <label htmlFor="searchDate" className="form-label"></label>
              <Box sx={{ minWidth: 200 }} style={{ marginTop: "0px" }}>
                <FormControl fullWidth>
                  <InputLabel
                    id="demo-simple-select-label"
                    style={{ marginTop: "-7px" }}
                  >
                    Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedStatus}
                    label="Status"
                    size="small"
                    onChange={(e) => setSelectedStatus(e.target.value)}
                  >
                    <MenuItem value="5">In progress</MenuItem>
                    <MenuItem value="7">3D Printing</MenuItem>
                    <MenuItem value="13">Delivered to Patient</MenuItem>
                    <MenuItem value="14">Cancelled</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
            {/* <div
                onClick={getTodayRequests}
                className="float-start mt-4 mx-3 p-2 cursor-pointer filter-today"
              >
                Today
              </div>
              <div
                onClick={() => filterStatus("5")}
                className="float-start mt-4 mx-3 p-2 cursor-pointer filter-progress"
              >
                In Progress
              </div>
              <div
                onClick={() => filterStatus("13")}
                className="float-start mt-4 mx-3 p-2 cursor-pointer filter-delivered"
              >
                Delivered to Patient
              </div>
              <div
                onClick={() => filterStatus("7")}
                className="float-start mt-4 mx-3 p-2 cursor-pointer filter-printing"
              >
                3D Printing
              </div>
              <div
                onClick={() => filterStatus("14")}
                className="float-start mt-4 mx-3 p-2 cursor-pointer filter-cancelled"
              >
                Cancelled
              </div> */}
          </div>

          <TableContainer
            sx={{ marginTop: 4 }}
            className="request-table-content"
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{ backgroundColor: "#dbdce0" }}>
                <TableRow>
                  <TableCell>ID</TableCell>
                  {window.innerWidth >= 768 ? (
                    <TableCell>Branch Name</TableCell>
                  ) : null}
                  <TableCell>Date</TableCell>
                  {window.innerWidth >= 768 ? (
                    <TableCell>Patient Mobile</TableCell>
                  ) : null}
                  <TableCell>Model Viewer</TableCell>
                  {window.innerWidth >= 768 ? (
                    <TableCell>Anatomy</TableCell>
                  ) : null}
                  {window.innerWidth >= 768 ? (
                    <TableCell>Services</TableCell>
                  ) : null}
                  <TableCell>Status</TableCell>
                  {window.innerWidth >= 768 ? (
                    <TableCell>Note</TableCell>
                  ) : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.results?.length > 0 ? (
                  tableData.results.map((req) => (
                    <TableRow key={req.id}>
                      <TableCell>
                        <Link
                          to={
                            req.service.type.id === 2
                              ? `/RequestTracking_3D/${req.id}`
                              : req.service.type.id === 3
                              ? `/RequestTracking_360/${req.id}`
                              : ""
                          }
                        >
                          {req.id}
                        </Link>
                      </TableCell>
                      {window.innerWidth >= 768 ? (
                        <TableCell>{req.branchName}</TableCell>
                      ) : null}
                      <TableCell>
                        {new Date(req.created_at).toLocaleString()}
                      </TableCell>
                      {window.innerWidth >= 768 ? (
                        <TableCell>{req.patient.mobile}</TableCell>
                      ) : null}
                      <TableCell>
                        {req.result_URL ? (
                          <a
                            className="text-decoration-none"
                            target="_blank"
                            href={`https://view.qubx3d.com/${req.code}`}
                          >
                            {req.patient.fullname} Viewer
                          </a>
                        ) : (
                          <p className="mt-2">{`${req.patient.fullname} don't have viewer yet`}</p>
                        )}
                      </TableCell>
                      {window.innerWidth >= 768 ? (
                        <TableCell>
                          {req.anatomy ? req.anatomy.name : ""}
                        </TableCell>
                      ) : null}
                      {window.innerWidth >= 768 ? (
                        <TableCell>
                          {req.service.type.name} - ( {req.service.name} )
                        </TableCell>
                      ) : null}
                      <TableCell>
                        <Box style={getStyle(req.statusView)}>
                          {req.statusView}
                        </Box>
                      </TableCell>
                      {window.innerWidth >= 768 ? (
                        <TableCell style={{ padding: "5px" }}>
                          <div className="d-flex justify-content-between">
                            <img
                              src={
                                req.note
                                  ? "/images/requestTracking/note_activated.svg"
                                  : "/images/requestTracking/note_deactivated.svg"
                              }
                              alt="img"
                              className="mx-1 d-block"
                            />
                          </div>
                        </TableCell>
                      ) : null}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell className="text-center" colSpan={9}>
                      {" "}
                      Requests Not available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {tableData.results?.length > 0 ? (
              <div className="paginate-box d-flex justify-content-end p-2">
                <Box>
                  <FormControl>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={pageSize}
                      label=""
                      onChange={(e) => setPageSize(e.target.value)}
                    >
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={15}>15</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <button
                  disabled={!tableData.previous}
                  className="m-2 border-0 bg-transparent"
                  onClick={() => handlePageChange(1)}
                >
                  <Tooltip title={`First Page: 1`}>
                    <i className="fas fa-angle-double-left"></i>
                  </Tooltip>
                </button>
                <button
                  onClick={() => handlePageChange(tableData.page_number - 1)}
                  disabled={!tableData.previous}
                  className="m-2 mx-3 border-0 bg-transparent"
                >
                  <Tooltip
                    title={`Previous Page: ${tableData.page_number - 1}`}
                  >
                    <i className="fas fa-chevron-left"></i>
                  </Tooltip>
                </button>
                <p className="m-2 mt-3">{`${tableData.from}-${tableData.to}  of  ${tableData.count}`}</p>

                <button
                  className="m-2 mx-3 border-0 bg-transparent"
                  disabled={!tableData.next}
                  onClick={() => handlePageChange(tableData.page_number + 1)}
                >
                  <Tooltip title={`Next Page: ${tableData.page_number + 1}`}>
                    <i className="fas fa-chevron-right"></i>
                  </Tooltip>
                </button>
                <button
                  className="m-2 border-0 bg-transparent"
                  disabled={!tableData.next}
                  onClick={() => handlePageChange(tableData.total_pages)}
                >
                  <Tooltip title={`Last Page: ${tableData.total_pages}`}>
                    <i className="	fas fa-angle-double-right	"></i>
                  </Tooltip>
                </button>
              </div>
            ) : (
              ""
            )}
          </TableContainer>
        </div>
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content text-center w-75 m-auto">
              <div className="modal-header border-0 ms-4">
                <h5 className="modal-title" id="exampleModalLabel">
                  note
                </h5>
              </div>
              <div className="modal-body">
                <textarea className="description-area p-4"></textarea>
              </div>
              <div className="modal-footer border-0 me-4">
                <button
                  type="button"
                  className="btn btn-secondary"
                  // data-bs-dismiss="modal"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="button" className="btn btn-primary">
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewRelatedCases;
