import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../../header/header";
import Footer from "../../../footer/footer";
import {
  httpNonToken,
  endpoints,
  basicUrl,
} from "../../../../globalConfig/http-non-token";
import Styles from "../../../../styles/requestTracking.module.css";
// import { convertZipFile } from "../../../globalConf/zipping";
import axios from "axios";
// import CustomToastify, { toastMes } from "../../../globalConf/notification";
import CustomToastify, {
  toastMes,
} from "../../../../globalConfig/notification";
import Button from "@mui/material/Button";

import { Box, FormControl } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Loading from "../../../loading/loading";
import { useSelector, useDispatch } from "react-redux";
import { addNote } from "../../../../features/note/noteSlice";
import "../../../../styles/style.css";

const RequestTracking_360 = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [body, setBody] = useState("");
  const [load, setLoad] = useState(true);
  const [flag, setFlag] = useState(false);
  const [request, setRequest] = useState("");
  const [requests, setRequests] = useState(id);
  const [allNotes, setAllNotes] = useState([]);
  const [created_by, setCreated_by] = useState("");

  const user = JSON.parse(localStorage.getItem("doctor"));

  useEffect(() => {
    (async () => {
      if (user) {
        try {
          const reqestData = await httpNonToken.get(
            `${endpoints.request}/${id}`,
            {
              headers: {
                Authorization: `Bearer ${user.access}`,
              },
            }
          );
          await httpNonToken.post(`${endpoints.request}/${id}/readNotes`, {
            read: user.user.groups[0].toString(),
          });
          const NoteData = await httpNonToken.get(
            `${endpoints.notes}?requests=${id}`
          );
          setCreated_by(user.user.id);
          setRequest(reqestData.data);
          setAllNotes(NoteData.data);
          setLoad(false);
        } catch ({ response }) {
          if (response.status === 401 && response.data.code) {
            localStorage.removeItem("doctor");
            navigate("/login");
            return;
          }
        }
      } else {
        navigate("/login");
      }
    })();
  }, [flag]);

  const handleSendNote = (e, category) => {
    try {
      e.preventDefault();
      const data = { body, requests, category, created_by };
      dispatch(addNote(data));
      setFlag(!flag);
      setBody("");
    } catch (error) {
    }
  };

  const getLinkCopied = (code) => {
    navigator.clipboard.writeText(`https://view.qubx3d.com/${code}`);
    toastMes.success("Link Copied To Clipboard Successfully");
  };

  const getStyle = (id) => {
    let style = {};
    style.borderRadius = "0px";
    style.textAlign = "center";
    style.padding = "5px 18px";
    if (id === "In progress") {
      style.backgroundColor = "#77c3f6";
    } else if (id === "Delivered to Patient") {
      style.backgroundColor = "#A6efb5";
    } else if (id === "3D Printing") {
      style.backgroundColor = "#D5a4ff";
    } else if (id === "Cancelled") {
      style.backgroundColor = "#E6e6e6";
    } else {
      style.backgroundColor = "lightgrey";
    }
    return style;
  };

  const getBgStyle = (data) => {
    let style = {};
    if (data) {
      style.backgroundColor = "#ddebff";
    } else {
      style.backgroundColor = "white";
    }
    return style;
  };

  if (load) {
    return <Loading />;
  }

  return (
    <div className="bg_dark">
      <Header />
      <div
        className={`${Styles.RequestTrackingHeight} bg_light p-2 rounded-top-20`}
      >
        <div className="m-4 overflow-hidden">
          <div className="overflow-hidden fn_main fn_sm d-flex align-items-center">
            <div className="float-start me-2">
              <button
                onClick={() => navigate(-1)}
                className="fw-bold border-0 f-primary bg-transparent btn-back"
              >
                <ArrowBackIosNewIcon />
                <h5 className="float-end text-primary">{request.id}</h5>
              </button>
            </div>
            <div
              className="me-2 request-box-info"
              style={{ position: "relative" }}
            >
              <h6 style={getStyle(request.statusView)}>{request.statusView}</h6>
              <FormControl
                sx={{ m: 1, minWidth: 120 }}
                style={{ position: "absolute", top: "-10px" }}
                size="small"
              ></FormControl>
            </div>
            <div className="mb-1">
              <span>{new Date(request.created_at).toLocaleString()}, </span>
              <span>{request.created_by.fullname}, </span>
              <span className="text-uppercase">{request.branchName}</span>
            </div>
          </div>
          <div className="row">
            <div className={`col-lg-7 ms-3 ${Styles.info_boxes}`}>
              <div className="row">
                <div
                  className={`col text-center bg-white border border-primary me-3 ${Styles.info_box}`}
                >
                  <img
                    alt="patient card"
                    className="w-50 d-block mx-auto my-3"
                    src={"/images/global/nurse_patient.svg"}
                  />
                  <h6 className="mb-1">{request.patient.fullname}</h6>
                  <p className="mt-1" style={{ fontSize: "12px" }}>
                    {request.patient.mobile}
                  </p>
                </div>
                <div className="col text-center bg-white border border-primary me-3 info-box">
                  <img
                    alt="doctor card"
                    className="w-50 d-block mx-auto my-3"
                    src={"/images/global/medical_doctor.svg"}
                  />
                  <h6 className="mb-1">{request.doctor?.fullname}</h6>
                  <p className="mt-1" style={{ fontSize: "12px" }}>
                    {request.doctor?.mobile}
                  </p>
                </div>
                <div className="col text-center bg-white border border-primary me-3 info-box">
                  <img
                    alt="service card"
                    className="w-50 d-block mx-auto my-3"
                    src={"/images/global/360-printing.svg"}
                  />
                  <h6>{request.service.type.name}</h6>
                  <p style={{ fontSize: "14px" }}>{request.service.name}</p>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-lg-12">
                  <Box sx={{ minWidth: 200 }}>
                    <div className="row">
                      <p className="col-12">Request URL</p>
                      <div className="col-12">
                        {request.result_URL ? (
                          <p>
                            <i
                              className="fa-solid fa-copy text-primary mx-3 fa-lg"
                              onClick={() => getLinkCopied(request.code)}
                              style={{ cursor: "pointer" }}
                            ></i>
                            <a
                              target="_blank"
                              href={`https://view.qubx3d.com/${request.code}`}
                            >
                              {`https://view.qubx3d.com/${request.code}`}
                            </a>
                          </p>
                        ) : (
                          <p className={Styles.noResults}>
                            There is no results
                          </p>
                        )}
                      </div>
                    </div>
                  </Box>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                style={getBgStyle(request.attach)}
                className="border-primary border_dash rounded mb-3"
              >
                <ul className="list-unstyled p-1">
                  <li className="mb-2 d-flex justify-content-between px-3">
                    {request.attach ? (
                      <a href={request.attach} target="_blank">
                        {" "}
                        {request.patient.fullname} Document
                      </a>
                    ) : (
                      "Patient has no document"
                    )}
                  </li>
                </ul>
              </div>
              <div
                style={getBgStyle(request.DICON_URL)}
                className="border-primary border_dash rounded mb-3"
              >
                <ul className="list-unstyled p-1">
                  <li className="mb-1 d-flex justify-content-between px-3 mt-3">
                    <h6>DICOM Data</h6>
                    {request.DICON_source.id === 3 ? (
                      request.DICON_File ? (
                        <a href={request.DICON_File} target="_blank">
                          {request.patient.fullname} Data
                        </a>
                      ) : (
                        "Patient have no documents"
                      )
                    ) : null}
                    {request.DICON_source.id === 4 ? (
                      request.DICON_URL ? (
                        <a href={request.DICON_URL} target="_blank">
                          {request.patient.fullname} Data
                        </a>
                      ) : (
                        "Patient have no documents"
                      )
                    ) : null}
                  </li>
                </ul>
              </div>

              {request.descraption ? (
                <div>
                  <p>
                    <b>Case Description: </b>
                    {request.descraption}
                  </p>
                </div>
              ) : null}

              <div className="bg-wite">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="contact-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#contact"
                      type="button"
                      role="tab"
                      aria-controls="contact"
                      aria-selected="false"
                    >
                      Qubx
                    </button>
                  </li>
                </ul>

                <div
                  className="tab-content bg-white overflow-scroll h_300"
                  id="myTabContent"
                >
                  {/**** First Tab ****/}
                  <div
                    className="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    {allNotes.length > 0 &&
                      allNotes.map((note) =>
                        note.category.id === 4 ? (
                          <div className="box p-2" key={note.id}>
                            <div
                              className="info-msg me-3 text-end"
                              id={
                                note.created_by.groups.some((elem) =>
                                  user.user.groups.includes(elem)
                                )
                                  ? "sender"
                                  : ""
                              }
                            >
                              <span className="time d-block fn_xxs f-main">
                                {note.created_by.fullname}
                                {" - "}
                                {new Date(note.created_at).toLocaleString()}
                              </span>
                            </div>
                            <div className="content-msg p-2 mx-2 rounded bg_light fn_sm">
                              {note.body}
                            </div>
                          </div>
                        ) : (
                          ""
                        )
                      )}

                    <div className="p-2 text-center mx-2">
                      <textarea
                        className="border border-secondry rounded w-100"
                        onChange={(e) => setBody(e.target.value)}
                        value={body}
                      ></textarea>
                      <button
                        className="btn btn-primary float-end"
                        type="submit"
                        onClick={(e) => handleSendNote(e, 4)}
                      >
                        send
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomToastify duration={8000} />
    </div>
  );
};

export default RequestTracking_360;
