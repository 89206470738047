import { Routes, Route, Navigate } from "react-router-dom";
import "../src/styles/main.css";
import "./App.css";

import Login from "./components/doctor/pages/login";
import Footer from "./components/footer/footer";
import RequestTracking_360 from "./components/doctor/pages/relatedCases/RequestTracking_360";
import RequestTracking_3D from "./components/doctor/pages/relatedCases/RequestTracking_3D";
import ChangePasswaord from "./components/changePassword/ChangePasswaord";

import Error from "./components/error/error";

// import Signup from "./components/doctor/pages/signup";
import ViewRelatedCases from "./components/doctor/pages/relatedCases/viewRelatedCases";

function App() {
  const user = JSON.parse(localStorage.getItem("doctor"));
  return (
    <>
      <div className="App" style={{ minHeight: "100vh" }}>
        <Routes>
          <Route exact path="/" element={<Navigate replace to="/requests" />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/requests" element={<ViewRelatedCases />} />
          <Route
            exact
            path="/reset-password/:id"
            element={<ChangePasswaord />}
          />

          <Route
            exact
            path="/RequestTracking_360/:id"
            element={<RequestTracking_360 />}
          />
          <Route
            exact
            path="/RequestTracking_3D/:id"
            element={<RequestTracking_3D />}
          />
          <Route path="/not-found" element={<Error />} />
          <Route path="*" element={<Navigate replace to="/not-found" />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
}

export default App;
