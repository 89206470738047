// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".changePassword_p_warning__nXrqN {\r\n    width: 95% !important;\r\n  }\r\n  .changePassword_img_warning__d6ksh {\r\n    float: left;\r\n    margin: 21px 5px 0 20px;\r\n  }\r\n  \r\n  @media only screen and (max-width: 900px) {\r\n    .changePassword_content__hYu3g {\r\n      width: 70% !important;\r\n    }\r\n  }\r\n  \r\n  ", "",{"version":3,"sources":["webpack://./src/components/changePassword/changePassword.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;EACvB;EACA;IACE,WAAW;IACX,uBAAuB;EACzB;;EAEA;IACE;MACE,qBAAqB;IACvB;EACF","sourcesContent":[".p_warning {\r\n    width: 95% !important;\r\n  }\r\n  .img_warning {\r\n    float: left;\r\n    margin: 21px 5px 0 20px;\r\n  }\r\n  \r\n  @media only screen and (max-width: 900px) {\r\n    .content {\r\n      width: 70% !important;\r\n    }\r\n  }\r\n  \r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"p_warning": "changePassword_p_warning__nXrqN",
	"img_warning": "changePassword_img_warning__d6ksh",
	"content": "changePassword_content__hYu3g"
};
export default ___CSS_LOADER_EXPORT___;
