import React from "react";
import "./loading.css";

const Loading = () => {
  return (
    <div className="container containerLoading">
      <span>
        <img
          style={{ width: "140px", margin: "auto" }}
          src="/images/loading/qubx-logo-black.png"
          alt="logo"
        />
      </span>
      <div className="line">
        <div className="inner"></div>
      </div>
    </div>
  );
};

export default Loading;
