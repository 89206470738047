import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../styles/header.css";

const Header = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("doctor"));

  const handleLogout = () => {
    localStorage.removeItem("doctor");
    navigate("/login");
  };

  const changePassword = () => {
    navigate(`/reset-password/${user.user.id}`, {
      state: { changePasswordFlag: true },
    });
  };

  return (
    <>
      <header className="w-100 h-25 py-2 d-flex justify-content-between header-doctor">
        <div style={{ width: "150px" }} className="ms-5 logo">
          <Link to="/requests">
            <img
              className="w-100"
              // src="/header-images/qubx_logo.svg"
              src="/header-images/logo.png"
              alt="Logo"
            />
          </Link>
        </div>
        <div className="profile-doctor-dropdown d-flex justify-content-center align-items-center ">
          <div className="dropdown">
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle d-inline text-white"
                to="#"
                id="navbarDarkDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {user ? user.user.fullname : ""}
              </Link>
              <ul
                className="dropdown-menu dropdown-menu-dark"
                aria-labelledby="navbarDarkDropdownMenuLink"
              >
                <li>
                  <Link className="dropdown-item" to="/requests">
                    Home
                  </Link>
                </li>

                <li>
                  <span
                    className="dropdown-item cursor-pointer"
                    onClick={changePassword}
                  >
                    Change password
                  </span>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/login"
                    onClick={handleLogout}
                  >
                    Logout
                  </Link>
                </li>
              </ul>
            </li>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
