import React, { useState, useEffect } from "react";
import Footer from "../footer/footer";
import Styles from "./changePassword.module.css";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { httpNonToken, endpoints } from "../../globalConfig/http-non-token";
import CustomToastify, { toastMes } from "../../globalConfig/notification";
import Loading from "../loading/loading";
import "./change-password.css";

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [success, setSuccess] = useState(false);
  const [loadingFlag, setLoadingFlag] = useState(true);
  const [user, setUser] = useState({});

  const { state } = useLocation();

  let { id } = useParams();
  let navigate = useNavigate();

  if (!JSON.parse(localStorage.getItem("doctor"))) {
    navigate("/login");
  }

  useEffect(() => {
    let isMounted = true;
    setLoadingFlag(true);
    (async () => {
      try {
        const userRes = await httpNonToken.get(`${endpoints.user}${id}`);

        if (isMounted) {
          setUser(userRes.data);
          setLoadingFlag(false);
        }
      } catch ({ response }) {
        setSuccess(false);
        toastMes.error(response.data.message);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  if (loadingFlag) {
    return <Loading />;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!oldPassword.trim() || !newPassword.trim()) {
      setSuccess(false);
      toastMes.error(`empty filed not allowed`);
    } else {
      try {
        let updatePasswordRes = await httpNonToken.put(
          `${endpoints.changePassword}${id}`,
          {
            oldPassword,
            newPassword,
          }
        );
        if (updatePasswordRes.status === 200) {
          setSuccess(true);
          toastMes.error(`Password updated successfully`);
          navigate("/login");
        }
      } catch ({ response }) {
        setSuccess(false);
        toastMes.error(response.data.message);
      }
    }
  };

  return (
    <>
      <div
        style={{ marginTop: "120px", marginBottom: "170px" }}
        className={`bg-white text-center w_40 rounded mx-auto p-3 changePasswordSection ${Styles.content}`}
      >
        <h4 className="text-capitalize fn_main my-3">
          welcome {user.branch.name}!
        </h4>
        {state.changePasswordFlag ? null : (
          <div className="bg-danger m-auto rounded p-0 w-80 border">
            <img
              src="/images/global/warning.svg"
              alt="warning"
              className={Styles.img_warning}
            />

            <p
              className={`text-capitalize text-white p-3 m-0 fn_sm ${Styles.p_warning}`}
            >
              you are logging for the first time. you can use this form to
              change your password
            </p>
          </div>
        )}
        <div className="text-start mt-4">
          <label htmlFor="Password" className="form-label">
            Old Password
          </label>
          <input
            type="password"
            name="Password"
            className="form-control  bg-light"
            id="Password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            aria-describedby="emailHelp"
          />
        </div>

        <div className="text-start mt-4">
          <label htmlFor="confirmPassword" className="form-label">
            New Password
          </label>
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            name="confirmPassword"
            className="form-control bg-light"
            id="confirmPassword"
          />
        </div>

        <div className="mt-4 d-flex justify-content-between buttons-g">
          <button
            className="btn btn-primary w_40 mx-3"
            onClick={() => navigate("/")}
          >
            cancel
          </button>
          <button className="btn btn-primary w_40 mx-3" onClick={handleSubmit}>
            submit
          </button>
        </div>
      </div>
      <div>{/* <Footer /> */}</div>
      <CustomToastify bgColor={success ? "green" : "red"} duration={8000} />
    </>
  );
};

export default ChangePassword;
