import axios from "axios";
import { useNavigate } from "react-router-dom";

// Login user
const login = async (userData) => {
  const response = await axios.post(
    "https://py.qubx3d.com/api/v1/login",
    userData
  );

  if (response.data) {
    localStorage.setItem("doctor", JSON.stringify(response.data));
  }

  return response.data;
};

// Logout user
const logout = () => {
  localStorage.removeItem("doctor");
};

const authService = {
  logout,
  login,
};

export default authService;
